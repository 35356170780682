// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";

//import styles
import '../../assets/styles/containerStyles/about.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import { InputGroup } from "../../components/uiElements/inputGroup";
import { LinkButton } from "../../components/buttons/buttons";
import { LanguageTabs } from "../../components/uiElements/Tabs";
// Import utils
import {
    changeStateField, deleteItem,
    getInputValues, getPatchReqData,
    getTranslatableInputValues, getTranslationData,
    initTranslations, toggleStateField,
} from "../../utils/helperFunctions";
import { mapStateToProps } from "../../redux/mapStateToProps";
import { GetAboutData, UpdateAboutData } from "../../redux/actions";
import { validateTranslations } from "../../utils/validateTranslations";
import ItemsList from "../../components/ItemsList";
import PageHeader from "../../components/pageContentViews/pageHeader";
import {MediaFileCard} from "../../components/media/MediaFileCard";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import {Editor} from "../../components/uiElements/Editor";

class About extends Component {
    constructor(props){
        super(props);
        this.translationsFields = [
            'title',
            'description',
            'subDescription',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                mediaMain: undefined,
            },
            errors: {},
            errorsTabs: [],
            isEditing: true,
            languageTab: props.mainLanguage,
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.setAboutData = this.setAboutData.bind(this);
        this.updateAbout = this.updateAbout.bind(this);
        this.deleteAbout = this.deleteAbout.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);

    }

    async componentDidMount(){
        await this.props.GetAboutData();
        const { about } = this.props;
        this.setAboutData(about)
    }

    setAboutData(about){
        const { fieldsData } = this.state;
        const translationsData = {};

        if ( about ) {
            Object.keys(fieldsData.translations).forEach(key => {
                const trItem = about.translations.find(item => item.languageId === key);
                translationsData[key] = {
                    ...fieldsData.translations[key],
                    title: trItem?.title || '',
                    description: trItem?.description || '',
                    subDescription: trItem?.subDescription || '',
                };
            });
        }

        const initStateData = {
            translations: translationsData,
            mediaMain: about?.mediaMain,
        }
        this.currentData = JSON.parse(JSON.stringify(initStateData));
        this.setState({
            fieldsData: initStateData,
            errors: {},
            errorsTabs: [],
            requestLoading: false,
        });
    }

    getMedia(mediaArray) {
        const {fieldsData, errors, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.mediaMain?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set('mediaMain', mediaArray[0]?.id)
                : this.updatedDataMap.delete('mediaMain');
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaMain: mediaArray[0],
            },
            errors: {
                ...errors,
                mediaMain: false
            }
        })
    }
    deleteMedia() {
        const {fieldsData, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.mediaMain ?
                this.updatedDataMap.set("mediaMain", null) :
                this.updatedDataMap.delete("mediaMain");
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaMain: null
            }
        })
    }

    async updateAbout(){
        await this.setState({
            requestLoading: true
        });
        const { translations,mediaMain} = this.state.fieldsData;
        const translationRequiredFields = ['title', 'description'];
        const validationTr = validateTranslations(translations, translationRequiredFields);
        let result = validationTr.result;
        const errors = { ...validationTr.errors };

        if (!mediaMain) {
            errors.mediaMain = true;
            result = false;
        }

        if ( result ) {
            const reqData ={
                translations:getTranslationData(translations),
                mediaMain: mediaMain.id,
            }
            this.props.UpdateAboutData(reqData).then((updatedData) => {
                this.updatedDataMap.clear();
                this.setAboutData(updatedData)
            }).catch(() => {
            }).finally(() => {
                this.setState({ requestLoading: false });
            });

        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    getListInfo(data){
        let { mainLanguage } = this.props;
        return data?.map((item,index) => {
            const { translations } = item;
            const mainTranslations = translations?.find(item => item.languageId === mainLanguage);
            const { title } = mainTranslations || {};
            return {
                id: index,
                values: [
                    title ?? '-',
                ]
            }
        });
    }

    deleteAbout(index){
        const {about} = this.props;
        let data = about?.blocks.map(item => {
            return{
                translations:item?.translations,
                mediaMain:item.mediaMain?.id
            }
        })
        let reqData ={
            blocks:data.filter((item,num) => num !== index)
        }
        this.props.UpdateAboutData(reqData)
    }
    render(){
        const { about } = this.props;
        const { fieldsData, errors, languageTab, requestLoading,mediaModalOpen, isEditing, errorsTabs } = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};
        const itemListHeaderInfo = {
            generalInfo: ["Անվանում"],
            largeItems: [0],
            actions: ["edit"],
        };
        const blocksListInfo = this.getListInfo(about?.blocks);
        return <PageWrapper pageTitle={'Մեր Մասին'}>

            <section className="about">
                <div className="top-side">
                    <label>Նկար <span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.mediaMain ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModal}
                    />
                </div>
                {fieldsData?.mediaMain && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.mediaMain}
                                   customDelete={this.deleteMedia}
                    />
                </div>}
                <div className="info-wrapper">
                    <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                                  errorsTabs={errorsTabs}
                                  activeTab={languageTab}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Վերնագիր"
                                placeholder="Վերնագիր"
                                name="title"
                                value={currentData.title}
                                initValue={initData.title}
                                error={errors['title' + languageTab]}
                                maxLength={100}
                                required={true}
                                onChange={this.getTranslationValues}/>
                    <InputGroup inputType="textarea"
                                type="text"
                                label="Նկարագրություն"
                                placeholder="Նկարագրություն"
                                name="description"
                                value={currentData.description}
                                initValue={initData.description}
                                error={errors['description' + languageTab]}
                                maxLength={1000}
                                required={true}
                                onChange={this.getTranslationValues}/>
                    {/*<InputGroup inputType="textarea"*/}
                    {/*            type="text"*/}
                    {/*            label="Ենթանկարագրություն"*/}
                    {/*            placeholder="Ենթանկարագրություն"*/}
                    {/*            name="subDescription"*/}
                    {/*            value={currentData.subDescription}*/}
                    {/*            initValue={initData.subDescription}*/}
                    {/*            error={errors['subDescription' + languageTab]}*/}
                    {/*            maxLength={1000}*/}
                    {/*            required={false}*/}
                    {/*            onChange={this.getTranslationValues}/>*/}
                    <div className="editor-wrapper">
                        <label>Նկարագրություն</label>
                        <Editor value={currentData?.subDescription}
                                name={'subDescription'}
                                initValue={initData.subDescription}
                                error={errors['subDescription' + languageTab]}
                                onChange={this.getTranslationValues}/>
                    </div>
                </div>

                <div className="flex-wrapper-right">
                    <LinkButton title={'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.updateAbout}/>
                </div>

                <label className={'list-label'}>Բաժիններ</label>
                <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                           itemListInfo={blocksListInfo}
                           loading={requestLoading}
                           editLink={"/about/edit"}/>

                <MediaSelectorModal
                    isOpen={!!mediaModalOpen}
                    acceptTypes={ACCEPT_IMAGE_TYPES}
                    getMedia={this.getMedia}
                    closeModal={this.toggleMediaModal}
                />
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetAboutData,
    UpdateAboutData
};

export default connect(mapStateToProps, mapDispatchToProps)(About)
