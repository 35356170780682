import {PROJECT_CONSTS} from "../constants";

export const initialState = {
    projectList: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case PROJECT_CONSTS.GET_PROJECTS:
            return {
                ...state,
                projectList: action.payload || [],
            };
        case PROJECT_CONSTS.CREATE_PROJECT:
            return {
                ...state,
                projectList: [action.payload].concat(state.projectList)
            };
        case PROJECT_CONSTS.DELETE_PROJECT_BY_ID:
            return {
                ...state,
                projectList: state.projectList.filter(item => item.id !== action.payload)
            };
        default:
            return state;
    }
}
