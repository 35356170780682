import {BLOCKS_CONSTS, SERVICES_CONSTS} from "../constants";

export const initialState = {
    servicesData: null,
    servicesById: null,
    isDeleted:false,
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case SERVICES_CONSTS.SET_SERVICES_DATA:
            return {
                ...state,
                servicesData: action.payload ,
            };
        case SERVICES_CONSTS.GET_SERVICES_BY_ID:
            return {
                ...state,
                servicesById: action.payload || [],
            };
        case SERVICES_CONSTS.CREATE_SERVICE_ITEM:
            return {
                ...state,
                servicesData: [action.payload].concat(state.servicesData)
            };
        case SERVICES_CONSTS.DELETE_SERVICE_ITEM:
            return {
                ...state,
                servicesData: state.servicesData.filter(item => item.id !== action.payload)
            };
        default:
            return state;
    }
}
