import {initialState as auth} from "../reducers/auth";
import {initialState as utils} from "../reducers/utils";
import {initialState as languages} from "../reducers/languages";
import {initialState as moderators} from "../reducers/moderators";
import {initialState as staticTexts} from "../reducers/staticTexts";
import {initialState as media} from "../reducers/media";
import {initialState as composite} from "../reducers/composite";
import {initialState as slider} from "../reducers/slider";
import {initialState as partner} from "../reducers/partner";
import {initialState as general} from "../reducers/general";
import {initialState as subscribers} from "../reducers/subscribers";
import {initialState as about} from "../reducers/about";
import {initialState as services} from "../reducers/services";
import {initialState as blocks} from "../reducers/blocks";
import {initialState as project} from "../reducers/project";
import {initialState as sponsors} from "../reducers/sponsors";
import {initialState as members} from "../reducers/members";
import {initialState as news} from "../reducers/news";
import {initialState as home} from "../reducers/home";


const reducers = {
    auth,
    utils,
    languages,
    moderators,
    staticTexts,
    media,
    composite,
    slider,
    partner,
    general,
    subscribers,
    about,
    services,
    blocks,
    project,
    sponsors,
    members,
    news,
    home,
};

export default state => {
    const sData = {};
    Object.keys(reducers).forEach(selectorKey => {
        Object.keys(reducers[selectorKey]).forEach(key => {
            sData[key] = state[selectorKey][key];
        });
    });
    return sData;
};

