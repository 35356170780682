// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import components
import PageWrapper from "../../components/pageContentViews/pageWrapper";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetSponsors, DeleteSponsorById,} from "../../redux/actions";
import {deleteItem} from "../../utils/helperFunctions";
import PageHeader from "../../components/pageContentViews/pageHeader";
import Nestable from "react-nestable";
import {NestableItem} from "../../components/cards/nestableItem";


class Sponsors extends Component {
    constructor(props) {
        super(props);
        this.deleteProject = deleteItem.bind(this, props.DeleteSponsorById, 'Հովանավորը');
    }

    componentDidMount() {
        this.props.GetSponsors();
    }

    getNestableItems(list, parent = null) {
        const items = [];
        list && !!list.length && list.forEach((item, index) => {

            items.push({
                id: item.id,
                index: index,
                withImage: true,
                isHidden: item.isHidden,
                path: item?.mediaMain?.path,
                link: {
                    pathname: `sponsors/edit/${item.id}`,
                },
                deleteCb: () => this.deleteProject(item.id),
                children: []
            })
        });
        return items;
    }

    render() {
        const {sponsorsList} = this.props;
        let items = this.getNestableItems(sponsorsList);
        return <PageWrapper pageTitle={'Հովանավորներ'}>
            <PageHeader linkTitle={"Ավելացնել"}
                        addingLink={"/sponsors/add"}/>
            {items && items.length !== 0 &&
                <Nestable
                    items={items}
                    maxDepth={1}
                    handler={()=>{}}
                    renderItem={NestableItem}
                />}
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetSponsors,
    DeleteSponsorById,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sponsors);
