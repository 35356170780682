import {request, _urlBlocks, _urlPartner} from "../api";
import {BLOCKS_CONSTS, PARTNER_CONSTS} from "../constants";
import {notificationSuccess} from "../../components/uiElements/Notifications";
import {history} from "../../configs/history";

export const GetBlocks = (id) => {
    const requestData = {
        url:`${ _urlBlocks}/${id}`,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: BLOCKS_CONSTS.BLOCKS_GET,
                    payload: data
                });
            })
    }
};

export const GetBlocksService = (id) => {
    const requestData = {
        url: `${_urlBlocks}/${id}`,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: BLOCKS_CONSTS.BLOCKS_GET_BY_ID,
                    payload: data
                });
            })
    }
};

export const UpdateBlocksById = (id, data) => {
    const requestData = {
        url: `${_urlBlocks}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: BLOCKS_CONSTS.BLOCKS_UPDATE_BY_ID,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Բլոկը հաջողությամբ փոփոխվեց!"
                })
            })
            .catch(() => {
            })
    }
};

export const DeleteBlocksById = (id,data) => {
    const requestData = {
        url:  `${_urlBlocks}/${id}`,
        token: true,
        method: "DELETE",
        data:{blockId : data}
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: BLOCKS_CONSTS.DELETE_BLOCKS_BY_ID,
                    payload: data
                });
                // history.push('/services')
            })
            .catch(() => {})
    }
};

export const CreateBlocks = (id,data) => {
    const requestData = {
        url: `${_urlBlocks}/${id}`,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: BLOCKS_CONSTS.CREATE_BLOCKS,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Գործընկերը հաջողությամբ ստեղծվեց!"
                })
                // history.push('/service')
            })
            .catch(() => {
            })
    }
};