import {request, _urlSponsors} from "../api";
import {SPONSORS_CONSTS} from "../constants";
import {history} from "../../configs/history";
import {notificationSuccess} from "../../components/uiElements/Notifications";

export const GetSponsors = () => {
    const requestData = {
        url: _urlSponsors,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: SPONSORS_CONSTS.GET_SPONSORS,
                    payload: data
                });
            })
    }
};


export const DeleteSponsorById = id => {
    const requestData = {
        url:  `${_urlSponsors}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: SPONSORS_CONSTS.DELETE_SPONSOR_ITEM,
                    payload: id
                });
            })
            .catch(() => {})
    }
};

export const CreateSponsor = data => {
    const requestData = {
        url: _urlSponsors,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: SPONSORS_CONSTS.CREATE_SPONSOR_ITEM,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Հովանավորը հաջողությամբ ստեղծվեց!"
                })
                history.push('/sponsors')
            })
            .catch(() => {
            })
    }
};

export const UpdateSponsorById = (id, data) => {
    const requestData = {
        url: `${_urlSponsors}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: SPONSORS_CONSTS.UPDATE_SPONSOR_ITEM,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Հովանավորը հաջողությամբ փոփոխվեց!"
                })
                history.push('/sponsors')
            })
            .catch(() => {
            })
    }
};
