// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

//import styles
import '../../assets/styles/containerStyles/slider-add-edit.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import {LinkButton} from "../../components/buttons/buttons";
import {MediaFileCard} from "../../components/media/MediaFileCard";

// Import utils
import {
    changeStateField,
    getInputValues, getPatchReqData,
    getTranslatableInputValues,
    toggleStateField
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {CreateSponsor, UpdateSponsorById} from "../../redux/actions";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";


class AddEditSponsors extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fieldsData: {
                mediaMain: undefined,
            },
            errors: {},
            errorsTabs: [],
            isEditing: !!props?.id,
            languageTab: props.mainLanguage,
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.addEditSponsors = this.addEditSponsors.bind(this);
    }

    async componentDidMount() {
        const {isEditing} = this.state;


        if (isEditing) {
            const {sponsorsList} = this.props;
            const editingData = sponsorsList.find(sponsors => sponsors.id === this.props.id);

            const initStateData = {
                mediaMain: editingData?.mediaMain,
            }
            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                fieldsData: initStateData,
            });
        }
    }

    getMedia(mediaArray) {
        const {fieldsData, errors, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.mediaMain?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set('mediaMain', mediaArray[0]?.id)
                : this.updatedDataMap.delete('mediaMain');
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaMain: mediaArray[0],
            },
            errors: {
                ...errors,
                mediaMain: false
            }
        })
    }

    deleteMedia() {
        const {fieldsData, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.mediaMain ?
                this.updatedDataMap.set("mediaMain", null) :
                this.updatedDataMap.delete("mediaMain");
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaMain: null
            }
        })
    }

    async addEditSponsors() {
        await this.setState({
            requestLoading: true
        });
        const {mediaMain} = this.state.fieldsData;
        let result = true;
        const errors = []

        if (!mediaMain) {
            errors.mediaMain = true;
            result = false;
        }
        if (result) {

            if (!this.state.isEditing) {
                const reqData = {
                    mediaMain: mediaMain.id,
                };

                this.props.CreateSponsor(reqData).finally(() => {
                    this.setState({requestLoading: false});
                })
            } else {

                const reqData = {
                    mediaMain: mediaMain.id,
                };

                this.props.UpdateSponsorById(this.props.id, reqData)
                    .finally(() => {
                        this.setState({requestLoading: false});
                    });
            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
            })
        }
    }

    render() {
        const {fieldsData, errors, mediaModalOpen, requestLoading, isEditing,} = this.state;

        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Հովանավորը' : 'Փոփոխել Հովանավորը'}>
            <section className="slider-add-edit">
                <div className="top-side">
                    <label>Նկար <span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.mediaMain ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModal}
                    />
                </div>
                {fieldsData?.mediaMain && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.mediaMain}
                                   customDelete={this.deleteMedia}
                    />
                </div>}
                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                cb={this.addEditSponsors}/>
                </div>

            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreateSponsor,
    UpdateSponsorById
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditSponsors)
