// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";

//import styles
import '../../assets/styles/containerStyles/about.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import { InputGroup } from "../../components/uiElements/inputGroup";
import { LinkButton } from "../../components/buttons/buttons";
import { LanguageTabs } from "../../components/uiElements/Tabs";

// Import utils
import {
    changeStateField, deleteItem,
    getInputValues, getPatchReqData,
    getTranslatableInputValues, getTranslationData,
    initTranslations, toggleStateField,
} from "../../utils/helperFunctions";
import { mapStateToProps } from "../../redux/mapStateToProps";
import {
    CreateService,
    DeleteBlocksById,
    GetServiceById,
    GetServicesData,
    UpdateServicesData
} from "../../redux/actions";
import { validateTranslations } from "../../utils/validateTranslations";
import ItemsList from "../../components/ItemsList";
import {MediaFileCard} from "../../components/media/MediaFileCard";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import PageHeader from "../../components/pageContentViews/pageHeader";
import {history} from "../../configs/history";
import {NestableItem} from "../../components/cards/nestableItem";
import Nestable from "react-nestable";

class AddEditService extends Component {
    constructor(props){
        super(props);
        this.translationsFields = [
            'title',
            'description',
            'subTitle',
            'subDescription',
            'name',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                slug:undefined,
                link:undefined,
                mediaMain: undefined,
                mediaHome: undefined,
                blocks:[],
            },
            blocksItems:[],
            errors: {},
            errorsTabs: [],
            isEditing: !!props?.id,
            languageTab: props.mainLanguage,
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.toggleMediaHomeModal = toggleStateField.bind(this, 'mediaHomeModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getInputValues = getInputValues.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.onPositionChange = this.onPositionChange.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.getMediaHome = this.getMediaHome.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.deleteMediaHome = this.deleteMediaHome.bind(this);
        this.updateService = this.updateService.bind(this);
        this.deleteServices = this.deleteServices.bind(this);

    }

    async componentDidMount(){
         this.props.GetServicesData();
        const { servicesData} = this.props;
        const { isEditing} = this.state;

        if(isEditing){
            const {id} = this.props;
            await this.props.GetServiceById(id)
            const { fieldsData } = this.state;
            const translationsData = {};

            this.setState({
                blocksItems:this.getNestableItems(this.props.servicesById?.blocks)
            })


            if ( this.props.servicesById ) {
                Object.keys(fieldsData.translations).forEach(key => {
                    const trItem = this.props.servicesById?.translations.find(item => item.languageId === key);
                    translationsData[key] = {
                        ...fieldsData.translations[key],
                        title: trItem?.title || '',
                        description: trItem?.description || '',
                        subTitle: trItem?.subTitle || '',
                        subDescription: trItem?.subDescription || '',
                        name: trItem?.name || '',
                    };
                });
            }
            const initStateData = {
                translations: translationsData,
                mediaMain: this.props.servicesById?.mediaMain,
                slug: this.props.servicesById?.slug,
                link: this.props.servicesById?.link,
                mediaHome: this.props.servicesById?.mediaHome,
            }
            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                fieldsData: initStateData,
                errors: {},
                errorsTabs: [],
                requestLoading: false,
            });

        }
    }


    getMedia(mediaArray) {
        const {fieldsData, errors, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.mediaMain?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set('mediaMain', mediaArray[0]?.id)
                : this.updatedDataMap.delete('mediaMain');
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaMain: mediaArray[0],
            },
            errors: {
                ...errors,
                mediaMain: false
            }
        })
    }
    getMediaHome(mediaArray) {
        const {fieldsData, errors, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.mediaHome?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set('mediaHome', mediaArray[0]?.id)
                : this.updatedDataMap.delete('mediaHome');
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaHome: mediaArray[0],
            },
            errors: {
                ...errors,
                mediaHome: false
            }
        })
    }
    deleteMedia() {
        const {fieldsData, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.mediaMain ?
                this.updatedDataMap.set("mediaMain", null) :
                this.updatedDataMap.delete("mediaMain");
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaMain: null
            }
        })
    }
    deleteMediaHome() {
        const {fieldsData, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.mediaHome ?
                this.updatedDataMap.set("mediaHome", null) :
                this.updatedDataMap.delete("mediaHome");
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaHome: null
            }
        })
    }


    async updateService(){
        await this.setState({
            requestLoading: true
        });
        const {id} = this.props
        const { translations,mediaMain,mediaHome,blocks,link,slug} = this.state.fieldsData;
        const translationRequiredFields = ['title', 'description','subTitle','subDescription','name'];
        const validationTr = validateTranslations(translations, translationRequiredFields);
        let result = validationTr.result;
        const errors = { ...validationTr.errors };

        if (!mediaMain) {
            errors.mediaMain = true;
            result = false;
        }
        if (!slug) {
            errors.slug = true;
            result = false;
        }
        if (!mediaHome) {
            errors.mediaHome = true;
            result = false;
        }

        if ( result ) {
            if(!this.state.isEditing){
                const reqData = {
                    translations:getTranslationData(translations),
                    mediaMain: mediaMain.id,
                    mediaHome:mediaHome?.id,
                    blocks,
                    slug,
                    link
                };

                this.props.CreateService(reqData).finally(() => {
                    this.setState({requestLoading: false});
                    history.push(`/services`)
                })
            }else {
                const reqData = {
                    mediaMain: mediaMain.id,
                    mediaHome: mediaHome.id,
                    slug,
                    link,
                    translations: getTranslationData(translations)
                };
                this.props.UpdateServicesData(id,reqData).then((updatedData) => {
                    this.updatedDataMap.clear();
                    this.setServiceData(updatedData)
                }).catch(() => {
                }).finally(() => {
                    this.setState({ requestLoading: false });
                    history.push(`/services/edit/${id}`)
                });
            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }

    }
    getNestableItems(list, position) {
        if(!position){
            const {mainLanguage,id} = this.props;
            const items = [];
            list && !!list.length && list.map((item, index) => {
                const trData = item?.translations?.find(tr => tr.languageId === mainLanguage);
                const title = trData?.title || '';

                items.push({
                    id: item?.id,
                    title: title,
                    withImage: true,
                    path: item?.mediaMain?.path,
                    link: {
                        pathname:`/services/block/edit/${item?.id}`,
                    },
                    deleteCb: deleteItem.bind(this, this.deleteServices, 'Ծառայություն',id, item?.id),
                    children: []
                })
            });
            return items;
        }else {
            const {id} = this.props;
            const items = [];
            list && !!list.length && list.map((item, index) => {

                items.push({
                    id: item?.id,
                    title: item?.title,
                    withImage: true,
                    path: item?.path,
                    link: {
                        pathname:`/services/block/edit/${item?.id}`,
                    },
                    deleteCb: deleteItem.bind(this, this.deleteServices, 'Ծառայություն',id, item?.id),
                    children: []
                })
            });
            return items;
        }
    }

    deleteServices(id,itemId){
        this.props.DeleteBlocksById(id,itemId).finally(() => {
            this.props.GetServiceById(id)
        });
    }

    onPositionChange (items) {
        const {id} = this.props;
        const position = true;
        const blockArray = items.map(item => item.id);
        const blocks = items.map(item => item);

        this.setState({
            blocksItems:this.getNestableItems(blocks,position)
        })

        const reqData = {
            blocks: blockArray
        }
        this.props.UpdateServicesData(id,reqData).then((updatedData) => {
            this.updatedDataMap.clear();
        })
    }

    render(){
        const { fieldsData, errors, languageTab, requestLoading, isEditing,mediaModalOpen,mediaHomeModalOpen, errorsTabs } = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};


        return <PageWrapper pageTitle={'Ծառայություններ'}>

            <section className="about">
                <div className="top-side">
                    <label>Նկար <span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.mediaMain ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModal}
                    />
                </div>
                {fieldsData?.mediaMain && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.mediaMain}
                                   customDelete={this.deleteMedia}
                    />
                </div>}
                <div className="top-side">
                    <label>Գլխավոր էջի նկար <span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.mediaHome ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaHomeModal}
                    />
                </div>
                {fieldsData?.mediaHome && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.mediaHome}
                                   customDelete={this.deleteMedia}
                    />
                </div>}
                <div className="info-wrapper">
                    <InputGroup inputType="input"
                                type="text"
                                label="Հղում"
                                placeholder="Հղում"
                                maxLength={100}
                                name="link"
                                required={true}
                                value={fieldsData?.link}
                                error={errors['link']}
                                onChange={this.getInputValues}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Սլագ"
                                placeholder="Սլագ"
                                maxLength={100}
                                name="slug"
                                required={true}
                                value={fieldsData?.slug}
                                error={errors['slug']}
                                onChange={this.getInputValues}/>
                    <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                                  errorsTabs={errorsTabs}
                                  activeTab={languageTab}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Անուն"
                                placeholder="Անուն"
                                name="name"
                                value={currentData.name}
                                initValue={initData.name}
                                error={errors['name' + languageTab]}
                                maxLength={1000}
                                required={true}
                                onChange={this.getTranslationValues}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Գլխավոր էջի վերնագիր"
                                placeholder="Վերնագիր"
                                name="title"
                                value={currentData.title}
                                initValue={initData.title}
                                error={errors['title' + languageTab]}
                                required={true}
                                onChange={this.getTranslationValues}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Ենթավերնագիր"
                                placeholder="Ենթավերնագիր"
                                name="subTitle"
                                value={currentData.subTitle}
                                initValue={initData.subTitle}
                                error={errors['subTitle' + languageTab]}
                                required={true}
                                onChange={this.getTranslationValues}/>
                    <InputGroup inputType="textarea"
                                type="text"
                                label="Գլխավոր էջի նկարագրությունը"
                                placeholder="նկարագրությունը"
                                name="description"
                                value={currentData.description}
                                initValue={initData.description}
                                error={errors['description' + languageTab]}
                                maxLength={1000}
                                required={true}
                                onChange={this.getTranslationValues}/>
                    <InputGroup inputType="textarea"
                                type="text"
                                label="Ենթանկարագրություն"
                                placeholder="Ենթանկարագրություն"
                                name="subDescription"
                                value={currentData.subDescription}
                                initValue={initData.subDescription}
                                error={errors['subDescription' + languageTab]}
                                maxLength={1000}
                                required={true}
                                onChange={this.getTranslationValues}/>
                </div>

                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.updateService}/>
                </div>

                {isEditing ? <div>
                    <label className={'list-label'}>Բաժիններ</label>
                    <PageHeader linkTitle={"Ավելացնել"}
                                addingLink={`/services/block/add`}/>

                    <Nestable
                        items={this.state.blocksItems}
                        maxDepth={1}
                        onChange={this.onPositionChange}
                        renderItem={NestableItem}
                    />

                </div> : ''
                }
            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
            <MediaSelectorModal
                isOpen={!!mediaHomeModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMediaHome}
                closeModal={this.toggleMediaHomeModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetServicesData,
    UpdateServicesData,
    GetServiceById,
    DeleteBlocksById,
    CreateService,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditService)
