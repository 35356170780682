// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import Nestable from "react-nestable";

// Import components
import {NestableItem} from "../../components/cards/nestableItem";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {deleteItem} from "../../utils/helperFunctions";
import {
    GetProjects,
    DeleteProjectById,
    UpdateProjectsPositions
} from "../../redux/actions";


class Project extends Component {
    constructor(props) {
        super(props);
        this.deleteProject = deleteItem.bind(this, props.DeleteProjectById, 'Նախագիծը');
        this.onPositionChange = this.onPositionChange.bind(this);
    }

    componentDidMount() {
        this.props.GetProjects();
    }

    getNestableItems(list, parent = null) {
        const {mainLanguage} = this.props;
        const items = [];
        list && !!list.length && list.forEach((item, index) => {
            const trData = item?.translations?.find(tr => tr.language === mainLanguage);
            const title = trData?.title || '';

            items.push({
                id: item.id,
                index: index,
                title: title,
                withImage: true,
                isHidden: item.isHidden,
                path: item?.mediaMain?.path,
                link: {
                    pathname: `project/edit/${item.id}`,
                },
                deleteCb: () => this.deleteProject(item.id),
                children: []
            })
        });
        return items;
    }

    async onPositionChange(items, item) {
        const movedProjectId = item.id;
        const {projectList} = this.props;
        const positionedItems = items.map((item, index) => {
            return {id: item.id, position: index}
        });
        if (~projectList.findIndex(m => m.id === movedProjectId) &&
            ~positionedItems.findIndex(m => m.id === movedProjectId)) {
            // first level menu was re-positioned in first level
            await this.props.UpdateProjectsPositions(positionedItems);
            this.props.GetProjects();
        }
    }

    render() {
        let {projectList} = this.props;
        let items = this.getNestableItems(projectList);
        return <PageWrapper pageTitle={'Նախագծեր'}>
            <PageHeader linkTitle={"Ավելացնել"}
                        addingLink={"/project/add"}/>
            {items && items.length !== 0 &&
            <Nestable
                items={items}
                maxDepth={1}
                onChange={this.onPositionChange}
                renderItem={NestableItem}
            />}
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetProjects,
    DeleteProjectById,
    UpdateProjectsPositions
};

export default connect(mapStateToProps, mapDispatchToProps)(Project);
