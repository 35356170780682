import {request, _urlServices} from "../api";
import {SERVICES_CONSTS} from "../constants";
import { notificationSuccess } from "../../components/uiElements/Notifications";
import {history} from "../../configs/history";

export const GetServicesData = () => {
    const requestData = {
        url: _urlServices,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: SERVICES_CONSTS.SET_SERVICES_DATA,
                    payload: data
                });
            })
    }
};

export const GetServiceById = (id) => {
    const requestData = {
        url: `${_urlServices}/${id}`,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: SERVICES_CONSTS.GET_SERVICES_BY_ID,
                    payload: data
                });
            })
    }
};

export const UpdateServicesData = (id,data) => {
    const requestData = {
        url: `${_urlServices}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: SERVICES_CONSTS.SET_SERVICES_DATA,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Ծառայությունները հաջողությամբ փոփոխվեց!"
                });
                // history.push('/services')
            })
    }
};

export const DeleteServiceById = id => {
    const requestData = {
        url:  `${_urlServices}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: SERVICES_CONSTS.DELETE_SERVICE_ITEM,
                    payload: id
                });
            })
            .catch(() => {})
    }
};

export const CreateService = data => {
    const requestData = {
        url: _urlServices,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: SERVICES_CONSTS.CREATE_SERVICE_ITEM,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Ծառայությունները հաջողությամբ ստեղծվեց!"
                })
                // history.push('/services')
            })
            .catch(() => {
            })
    }
};

export const UpdateServicesPositions = items => {
    const requestData = {
        url: _urlServices,
        token: true,
        method: "PUT",
        data: items
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: SERVICES_CONSTS.UPDATE_SERVICES_POSITIONS
                });
                notificationSuccess({
                    description: "Ծառայությունները հաջողությամբ թարմացվեց!"
                })
            })
            .catch(() => {
            })
    }
};