import { SPONSORS_CONSTS} from "../constants";

export const initialState = {
    sponsorsList: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SPONSORS_CONSTS.GET_SPONSORS:
            return {
                ...state,
                sponsorsList: action.payload || [],
            };
        case SPONSORS_CONSTS.CREATE_SPONSOR_ITEM:
            return {
                ...state,
                sponsorsList: [action.payload].concat(state.sponsorsList)
            };
        case SPONSORS_CONSTS.DELETE_SPONSOR_ITEM:
            return {
                ...state,
                sponsorsList: state.sponsorsList.filter(item => item.id !== action.payload)
            };
        default:
            return state;
    }
}
