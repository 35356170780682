// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";

//import styles
import '../assets/styles/containerStyles/about.scss';

// Import components
import PageWrapper from '../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../components/media/MediaSelectorModal";
import { InputGroup } from "../components/uiElements/inputGroup";
import { LinkButton } from "../components/buttons/buttons";
import { LanguageTabs } from "../components/uiElements/Tabs";
// Import utils
import {
    changeStateField,
    getInputValues, getPatchReqData,
    getTranslatableInputValues, getTranslationData,
    initTranslations,
    toggleStateField
} from "../utils/helperFunctions";
import { mapStateToProps } from "../redux/mapStateToProps";
import { validateTranslations } from "../utils/validateTranslations";
import { ACCEPT_IMAGE_TYPES } from "../constants/acceptedTypes";
import { generateFileMediaUrl } from "../utils/generateMemberMediaUrl";
import {GetHomeData, UpdateHomeData} from "../redux/actions";

class Home extends Component {
    constructor(props){
        super(props);
        this.translationsFields = [
            'title',
            'description',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                mediaMain: undefined,
            },
            errors: {},
            errorsTabs: [],
            isEditing: true,
            languageTab: props.mainLanguage,
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.setAboutData = this.setAboutData.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.updateAbout = this.updateAbout.bind(this);

    }

    async componentDidMount(){
        await this.props.GetHomeData();
        const { home } = this.props;
        this.setAboutData(home)
    }

    setAboutData(home){
        const { fieldsData } = this.state;
        const translationsData = {};

        if ( home ) {
            Object.keys(fieldsData.translations).forEach(key => {
                const trItem = home.translations.find(item => item.languageId === key);
                translationsData[key] = {
                    ...fieldsData.translations[key],
                    title: trItem?.title || '',
                    description: trItem?.description || '',
                };
            });
        }
        const initStateData = {
            translations: translationsData,
            mediaMain: home?.mediaMain,
        }
        this.currentData = JSON.parse(JSON.stringify(initStateData));
        this.setState({
            fieldsData: initStateData,
            errors: {},
            errorsTabs: [],
            requestLoading: false,
        });
    }

    getMedia(mediaArray){
        const { fieldsData, errors, isEditing } = this.state;
        if ( isEditing ) {
            this.currentData?.mediaMain?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set('mediaMain', mediaArray[0]?.id)
                : this.updatedDataMap.delete('mediaMain');
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaMain: mediaArray[0],
            },
            errors: {
                ...errors,
                mediaMain: false
            }
        })
    }

    async updateAbout(){
        await this.setState({
            requestLoading: true
        });
        const { translations, mediaMain, } = this.state.fieldsData;
        const translationRequiredFields = ['title', 'description'];
        const validationTr = validateTranslations(translations, translationRequiredFields);
        let result = validationTr.result;
        const errors = { ...validationTr.errors };

        if ( !mediaMain ) {
            errors.mediaMain = true;
            result = false;
        }
        if ( result ) {
            const trData = getTranslationData(translations);
            const reqData = this.getPatchReqData(trData);
            this.props.UpdateHomeData(reqData).then((updatedData) => {
                this.updatedDataMap.clear();
                this.setAboutData(updatedData)
            }).catch(() => {
            }).finally(() => {
                this.setState({ requestLoading: false });
            });

        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }


    render(){
        const { home } = this.props;
        const { fieldsData, errors, languageTab, mediaModalOpen, requestLoading, isEditing, errorsTabs } = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};


        return <PageWrapper pageTitle={'Մեր Մասին'}>

            <section className="about">
                <div className="top-side">
                    <label>Նկար <span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.mediaMain ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModal}/>
                </div>
                {fieldsData?.mediaMain && <div className={'background-image'}>
                    <img src={generateFileMediaUrl(fieldsData?.mediaMain?.path)}/>
                </div>}
                <div className="info-wrapper">
                    <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                                  errorsTabs={errorsTabs}
                                  activeTab={languageTab}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Վերնագիր"
                                placeholder="Վերնագիր"
                                name="title"
                                value={currentData.title}
                                initValue={initData.title}
                                error={errors['title' + languageTab]}
                                maxLength={100}
                                required={true}
                                onChange={this.getTranslationValues}/>
                    <InputGroup inputType="textarea"
                                type="text"
                                label="Նկարագրություն"
                                placeholder="Նկարագրություն"
                                name="description"
                                value={currentData.description}
                                initValue={initData.description}
                                error={errors['description' + languageTab]}
                                maxLength={1000}
                                required={true}
                                onChange={this.getTranslationValues}/>
                </div>

                <div className="flex-wrapper-right">
                    <LinkButton title={'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.updateAbout}/>
                </div>

            </section>

            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetHomeData,
    UpdateHomeData
};

export default connect(mapStateToProps, mapDispatchToProps)(Home)
