// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

//import styles
import '../../assets/styles/containerStyles/about.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";
import {MediaFileCard} from "../../components/media/MediaFileCard";

// Import utils
import {
    changeStateField, deleteItem,
    getInputValues,
    getPatchReqData,
    getTranslatableInputValues,
    getTranslationData,
    initTranslations,
    toggleStateField
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {CreateBlocks, GetBlocks, UpdateBlocksById} from "../../redux/actions";
import {validateTranslations} from "../../utils/validateTranslations";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import {history} from "../../configs/history";
import PageHeader from "../../components/pageContentViews/pageHeader";
import Nestable from "react-nestable";
import {NestableItem} from "../../components/cards/nestableItem";
import {Editor} from "../../components/uiElements/Editor";

class AddEditServiceBlock extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'title',
            'description',
            'subTitle',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                mediaMain: undefined,
                link:undefined,
                type: undefined,
                options:[],
            },
            errors: {},
            errorsTabs: [],
            isEditing: !!props?.id,
            languageTab: props.mainLanguage,
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.deleteServices = this.deleteServices.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.addEditServices = this.addEditServices.bind(this);

    }

    async componentDidMount() {
        const {id} = this.props;
        const {isEditing, fieldsData} = this.state;


        if (isEditing && !id) {
            history.push('/services');
            return;
        }

        if (isEditing) {
           await this.props.GetBlocks(id)
            const {blocksList} = this.props;
            const translationsData = {};
            const editingData = blocksList;
            if (editingData) {
                Object.keys(fieldsData.translations).forEach(key => {
                    const trItem = editingData.translations.find(item => item.languageId === key);
                    translationsData[key] = {
                        ...fieldsData.translations[key],
                        title: trItem?.title || '',
                        description: trItem?.description || '',
                        subTitle: trItem?.subTitle || '',
                    };
                });
            }
            const initStateData = {
                translations: translationsData,
                mediaMain: editingData?.mediaMain || '',
                link: editingData?.link || '',
                type: editingData?.type || '',
                options:editingData?.options || []
            };
            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                fieldsData: initStateData,
            });
        }
    }

    getMedia(mediaArray) {
        const {fieldsData, errors, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.mediaMain?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set('mediaMain', mediaArray[0]?.id)
                : this.updatedDataMap.delete('mediaMain');
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaMain: mediaArray[0],
            },
            errors: {
                ...errors,
                mediaMain: false
            }
        })
    }

    deleteMedia() {
        const {fieldsData, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.mediaMain ?
                this.updatedDataMap.set("mediaMain", null) :
                this.updatedDataMap.delete("mediaMain");
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaMain: null
            }
        })
    }


    async addEditServices() {
        await this.setState({
            requestLoading: true
        });
        const {translations, mediaMain,link,type,options} = this.state.fieldsData;
        const translationRequiredFields = [];
        const validationTr = validateTranslations(translations, translationRequiredFields);
        let result = validationTr.result;
        const errors = {...validationTr.errors};

        if (!link) {
            errors.link = true;
            result = false;
        }
        if (!type) {
            errors.type = true;
            result = false;
        }

        if (result) {
            if (!this.state.isEditing) {
                const {id} = this.props.servicesById;
                let reqData = {
                    translations:getTranslationData(translations),
                    mediaMain:mediaMain?.id || '' ,
                    link,
                    type,
                    options
                }
                if(!mediaMain?.id){
                    delete reqData.mediaMain
                }

                this.props.CreateBlocks(id,reqData).finally(() => {
                    this.setState({requestLoading: false});
                    history.push(`/services/edit/${id}`)
                })
            } else {
                const {id,blocksList} = this.props;
                const trData = getTranslationData(translations);
                const reqData = {
                    translations: trData,
                    mediaMain: mediaMain?.id || null,
                    link,
                    type,
                    options:blocksList?.options.map(item => {
                        return{
                            translations:item.translations,
                            mediaMain:item.mediaMain.id
                        }
                    })
                }
                this.props.UpdateBlocksById(id,reqData)
                    .finally(() => {
                        this.setState({requestLoading: false});
                        this.props.GetBlocks(id)
                    });
            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    getNestableItems(list, slug = null) {
        const {mainLanguage,id} = this.props;
        const items = [];
        list && !!list.length && list.map((item, index) => {
            const trData = item?.translations?.find(tr => tr.languageId === mainLanguage);
            const title = trData?.title || '';

            items.push({
                id: index,
                index: index,
                title: title,
                withImage: true,
                path: item?.mediaMain?.path,
                link: {
                    pathname:`/services/block/${id}/option/edit/${index}`,
                    state:{
                        slug:`${slug}`,
                        index:`${index}`
                    },
                },
                deleteCb: deleteItem.bind(this, this.deleteServices, 'Ծառայություն', index),
                children: []
            })
        });
        return items;
    }

    deleteServices(index){
        const {id,blocksList} = this.props;
        let data = blocksList?.options.map(item => {
            return{
                translations:item.translations,
                mediaMain:item.mediaMain.id
            }
        })
        let reqData = {
            options:data.filter((item,num) => num !== index)
            }
        this.props.UpdateBlocksById(id,reqData).finally(() => {
            this.props.GetBlocks(id)
        });
    }

    render() {
        const {fieldsData, errors, languageTab, mediaModalOpen, requestLoading, isEditing, errorsTabs} = this.state;
        const {blocksList} = this.props
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};
        const typesOptions = [
            {name:'INFORMATION',id:'INFORMATION'},
            {name:'ADVANTAGES',id:'ADVANTAGES'},
            {name:'SLIDER',id:'SLIDER'},
            {name:'POSSIBILITY',id:'POSSIBILITY'},
            {name:'TYPES',id:'TYPES'},
            {name:'MAIN_BLOCK',id:'MAIN_BLOCK'}
        ]

        let blocksItems = this.getNestableItems(blocksList?.options);
        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Ծառայություն' : 'Փոփոխել Ծառայություն'}>
            <section className="slider-add-edit">

                <div className="top-side">
                    <label>Նկար</label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.mediaMain ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModal}
                    />
                </div>
                {fieldsData?.mediaMain && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.mediaMain}
                                   customDelete={this.deleteMedia}
                    />
                </div>}


                <div className="info-wrapper">
                    <InputGroup inputType="selectCustom"
                                name="type"
                                label={"Տիպ"}
                                value={fieldsData.type}
                                initValue={currentData?.type}
                                withClear={true}
                                required={true}
                                error={errors['type']}
                                placeholder={'Տիպ'}
                                onChange={this.getInputValues}
                                options={typesOptions}
                    />
                    <InputGroup inputType="input"
                                type="text"
                                label="Հղում"
                                placeholder="Հղում"
                                maxLength={100}
                                name="link"
                                required={true}
                                value={fieldsData?.link}
                                error={errors['link']}
                                onChange={this.getInputValues}/>
                    <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                                  errorsTabs={errorsTabs}
                                  activeTab={languageTab}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Անուն"
                                placeholder="Անուն"
                                name="title"
                                value={currentData.title}
                                initValue={initData.title}
                                error={errors['title' + languageTab]}
                                maxLength={100}
                                required={false}
                                onChange={this.getTranslationValues}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Ենթավերնագիր"
                                placeholder="Ենթավերնագիր"
                                name="subTitle"
                                value={currentData.subTitle}
                                initValue={initData.subTitle}
                                error={errors['subTitle' + languageTab]}
                                maxLength={100}
                                required={false}
                                onChange={this.getTranslationValues}/>
                    <div className="editor-wrapper">
                        <label>Նկարագրություն</label>
                        <Editor value={currentData?.description}
                                name={'description'}
                                initValue={initData.description}
                                error={errors['description' + languageTab]}
                                onChange={this.getTranslationValues}/>
                    </div>
                </div>


                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.addEditServices}/>
                </div>

                {isEditing ? <div>
                        <label className={'list-label'}>Բաժիններ</label>
                        <PageHeader linkTitle={"Ավելացնել"}
                                    addingLink={`/services/block/${this.props.id}/option/add`}/>
                        <Nestable
                            items={blocksItems}
                            maxDepth={1}
                            handler={()=>{}}
                            renderItem={NestableItem}
                        />
                    </div> : ''
                }

            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreateBlocks,
    UpdateBlocksById,
    GetBlocks,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditServiceBlock)
