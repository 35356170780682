// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Switch} from "antd";

//import styles
import '../../assets/styles/containerStyles/slider-add-edit.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";
import {MediaFileCard} from "../../components/media/MediaFileCard";

// Import utils
import {
    changeStateField,
    getInputValues, getPatchReqData,
    getTranslatableInputValues, getTranslationData,
    initTranslations,
    toggleStateField
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {CreateProject, UpdateProjectById} from "../../redux/actions";
import {validateTranslations} from "../../utils/validateTranslations";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import isURL from "validator/es/lib/isURL";
import {MaterialIcon} from "../../components/utils/Icon";

class AddEditProject extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'title',
            'description',
            'options',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                mediaMain: undefined,
                link: '',
                isHidden: false,
            },
            errors: {},
            errorsTabs: [],
            isEditing: !!props?.id,
            languageTab: props.mainLanguage,
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.addEditProject = this.addEditProject.bind(this);
        this.addOptions = this.addOptions.bind(this);
        this.removeOptions = this.removeOptions.bind(this);
        this.getOptionsValues = this.getOptionsValues.bind(this);
    }

    async componentDidMount() {
        const {isEditing, fieldsData} = this.state;

        if (isEditing) {
            const translationsData = {};
            const {projectList} = this.props;
            const editingData = projectList.find(project => project.id === this.props.id);
            if (editingData) {
                Object.keys(fieldsData.translations).forEach(key => {
                    const trItem = editingData.translations.find(item => item.languageId === key);
                    translationsData[key] = {
                        ...fieldsData.translations[key],
                        title: trItem?.title || '',
                        description: trItem?.description || '',
                        options: trItem?.options || '',
                    };
                });
            }
            const initStateData = {
                translations: translationsData,
                link: editingData?.link || '',
                mediaMain: editingData?.mediaMain,
                isHidden: editingData?.isHidden,
            }
            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                fieldsData: initStateData,
            });
        }
    }

    getMedia(mediaArray) {
        const {fieldsData, errors, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.mediaMain?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set('mediaMain', mediaArray[0]?.id)
                : this.updatedDataMap.delete('mediaMain');
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaMain: mediaArray[0],
            },
            errors: {
                ...errors,
                mediaMain: false
            }
        })
    }

    deleteMedia() {
        const {fieldsData, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.mediaMain ?
                this.updatedDataMap.set("mediaMain", null) :
                this.updatedDataMap.delete("mediaMain");
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaMain: null
            }
        })
    }

    getOptionsValues ({ name, value, haveChanges }) {
        const { fieldsData, languageTab } = this.state;
        haveChanges
            ? this.updatedDataMap.set("options" + name, value)
            : this.updatedDataMap.delete("options" + name);
        let newAttributeList = [...fieldsData?.translations[languageTab]?.options];
        newAttributeList[name] = value;
        this.setState({
            fieldsData: {
                ...fieldsData,
                translations: {
                    ...fieldsData.translations,
                    [languageTab]: {
                        ...fieldsData.translations[languageTab],
                        options: newAttributeList
                    }
                }
            },
            errors: {
                ...this.state.errors,
                translations: {
                    ...fieldsData.translations,
                    [languageTab]: {
                        ...fieldsData.translations[languageTab],
                        [name]: false
                    }
                }
            },
        });
    }

    removeOptions (position) {
        const { fieldsData, languageTab,isEditing } = this.state;
        if (isEditing) {
            this.currentData?.translations[languageTab].options ?
                this.updatedDataMap.set('options',null ) :
                this.updatedDataMap.delete('options');
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                translations: {
                    ...fieldsData.translations,
                    [languageTab]: {
                        ...fieldsData.translations[languageTab],
                        options:fieldsData.translations[languageTab].options.filter((_, index) => position !== index)
                    }
                }
            },
        })
    }

    addOptions () {
        const { fieldsData, languageTab } = this.state;
        this.setState({
            fieldsData: {
                ...fieldsData,
                translations: {
                    ...fieldsData.translations,
                    [languageTab]: {
                        ...fieldsData.translations[languageTab],
                        options: [...fieldsData.translations[languageTab].options,'']
                    }
                }
            },
        })
    }

    async addEditProject() {
        await this.setState({
            requestLoading: true
        });
        const {translations, mediaMain, link, isHidden} = this.state.fieldsData;
        const translationRequiredFields = ['title'];
        const validationTr = validateTranslations(translations, translationRequiredFields);
        let result = validationTr.result;
        const errors = {...validationTr.errors};

        if (!mediaMain) {
            errors.mediaMain = true;
            result = false;
        }

        if (link && !isURL(link)) {
            errors.link = true;
            result = false;
        }
        if (result) {

            if (!this.state.isEditing) {
                const reqData = {
                    isHidden,
                    mediaMain: mediaMain.id,
                    translations: getTranslationData(translations)
                };

                if (link) reqData.link = link;

                this.props.CreateProject(reqData).finally(() => {
                    this.setState({requestLoading: false});
                })
            } else {

                const trData = getTranslationData(translations);
                const reqData = this.getPatchReqData(trData);
                this.props.UpdateProjectById(this.props.id, reqData)
                    .finally(() => {
                        this.setState({requestLoading: false});
                    });
            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    render() {
        const {fieldsData, errors, languageTab, mediaModalOpen, requestLoading, isEditing, errorsTabs} = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};

        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Նախագիծ' : 'Փոփոխել Նախագիծը'}>
            <section className="slider-add-edit">
                <div className="fixed-top-right">
                    <label>{!fieldsData.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
                    <Switch checked={!fieldsData.isHidden}
                            onChange={(checked) => this.getInputValues({
                                name: "isHidden",
                                value: !checked,
                                haveChanges: this.currentData?.isHidden !== !checked
                            })}/>
                </div>
                <div className="top-side">
                    <label>Նկար <span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.mediaMain ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModal}
                    />
                </div>
                {fieldsData?.mediaMain && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.mediaMain}
                                   customDelete={this.deleteMedia}
                    />
                </div>}

                <InputGroup inputType="input"
                            type="text"
                            label="Հղում"
                            placeholder="Հղում"
                            name="link"
                            value={fieldsData?.link}
                            initValue={this.currentData?.link}
                            error={errors?.link}
                            maxLength={100}
                            onChange={this.getInputValues}/>

                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}/>
                <div className="slider-wrapper">
                    <InputGroup inputType="input"
                                type="text"
                                label="Անվանում"
                                placeholder="Անվանում"
                                name="title"
                                value={currentData.title}
                                initValue={initData.title}
                                error={errors['title' + languageTab]}
                                maxLength={100}
                                required={true}
                                onChange={this.getTranslationValues}/>
                    <InputGroup inputType="textarea"
                                type="text"
                                label="Նկարագրություն"
                                placeholder="Նկարագրություն"
                                name="description"
                                value={currentData.description}
                                initValue={initData.description}
                                error={errors['description' + languageTab]}
                                maxLength={1000}
                                onChange={this.getTranslationValues}/>

                    <div className={'options'}>
                        <label className={'label'}>Նկարագրություն<span className={'required-badge'}>*</span></label>
                        {currentData?.options?.length
                            ? currentData?.options.map((options, index) => {
                                return <div className="item-row" key={index}>
                                    <InputGroup
                                        value={options}
                                        initValue={options}
                                        error={errors['options' + index + languageTab]}
                                        name={index}
                                        inputType={"input"}
                                        placeholder={"Նկարագրություն"}
                                        maxLength={100}
                                        onChange={this.getOptionsValues}
                                    />
                                    {currentData?.options?.length
                                        && <LinkButton className="bg-red delete-item-btn"
                                                       title={<MaterialIcon icon="delete"/>}
                                                       cb={() => this.removeOptions(index)}/>}

                                </div>
                            }) : ''}
                        <LinkButton title={<MaterialIcon icon={'add'}/>}
                                    cb={this.addOptions}/>
                    </div>
                </div>

                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.addEditProject}/>
                </div>

            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreateProject,
    UpdateProjectById
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditProject)
