// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import components
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {deleteItem} from "../../utils/helperFunctions";
import {
    GetSliders,
    DeleteSliderById,
} from "../../redux/actions";
import ItemsList from "../../components/ItemsList";


class Slider extends Component {


    componentDidMount() {
        this.props.GetSliders();
    }

    render() {
        let {sliderList,mainLanguage,requestLoading} = this.props;
        let itemListHeaderInfo = {
            generalInfo: ["Անվանում", "Գույն"],
            largeItems: [0, 2, 3],
            status: true,
            actions: ["edit", "delete"],
        };

        let itemListInfo = sliderList?.map((slider) => {
            const { id, translations, color, isHidden } = slider;
            const mainTranslations = translations?.find(item => item.languageId === mainLanguage);
            const { title } = mainTranslations || {};
            return {
                id,
                values: [
                    title ?? '-',
                    {
                        title: <div className="color"
                                    style={{ backgroundColor: color }}/>
                    }
                ],
                status: !isHidden,
                deleteCb: deleteItem.bind(this, this.props.DeleteSliderById, 'սլայդերը', id)
            }
        });
        return <PageWrapper pageTitle={'Սլայդեր'}>
            <PageHeader linkTitle={"Ավելացնել"}
                        addingLink={"/slider/add"}/>
            <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                       itemListInfo={itemListInfo}
                       loading={requestLoading}
                       editLink={"/slider/edit"}/>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetSliders,
    DeleteSliderById,
};

export default connect(mapStateToProps, mapDispatchToProps)(Slider);
