export const ContactAddingList = [
    {
        label: "Էլ․ հասցե",
        inputType: "input",
        type: "text",
        placeholder: "Էլ․ հասցե",
        name: "email",
        maxLength: 500
    },
    {
        label: "Ընդհանուր հեռախոսահամար",
        inputType: "input",
        type: "text",
        regExp:/^[+\d]\d*$/,
        placeholder: "Ընդհանուր հեռախոսահամար",
        name: "overallNumber",
        maxLength: 20
    },
    {
        label: "Վաճառքի հեռախոսահամար",
        inputType: "input",
        type: "text",
        regExp:/^[+\d]\d*$/,
        placeholder: "Վաճառքի հեռախոսահամար",
        name: "salesNumber",
        maxLength: 20
    },
    {
        label: "Սպասարկման հեռախոսահամար",
        inputType: "input",
        type: "text",
        regExp:/^[+\d]\d*$/,
        placeholder: "Սպասարկման հեռախոսահամար",
        name: "maintenanceNumber",
        maxLength: 20
    },
];
