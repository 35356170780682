import {request, _urlSliders} from "../api";
import {SLIDERS_CONSTS} from "../constants";
import {history} from "../../configs/history";
import {notificationSuccess} from "../../components/uiElements/Notifications";

export const GetSliders = () => {
    const requestData = {
        url: _urlSliders,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: SLIDERS_CONSTS.GET_SLIDERS,
                    payload: data
                });
            })
    }
};

export const GetSliderById = (id) => {
    const requestData = {
        url: `${_urlSliders}/${id}`,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: SLIDERS_CONSTS.GET_SLIDER_BY_ID,
                    payload: data
                });
            })
    }
};


export const DeleteSliderById = id => {
    const requestData = {
        url:  `${_urlSliders}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: SLIDERS_CONSTS.DELETE_SLIDER_ITEM,
                    payload: id
                });
            })
            .catch(() => {})
    }
};

export const CreateSlider = data => {
    const requestData = {
        url: _urlSliders,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: SLIDERS_CONSTS.CREATE_SLIDER_ITEM,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Սլայդերը հաջողությամբ ստեղծվեց!"
                })
                history.push('/slider')
            })
            .catch(() => {
            })
    }
};

export const UpdateSliderById = (id, data) => {
    const requestData = {
        url: `${_urlSliders}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: SLIDERS_CONSTS.UPDATE_SLIDER_ITEM,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Սլայդերը հաջողությամբ փոփոխվեց!"
                })
                history.push('/slider')
            })
            .catch(() => {
            })
    }
};
